import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface LayoutProps {
  title: string;
  children: React.ReactNode;
}

export const Layout = ({ title, children }: LayoutProps) => {
  const location = useLocation();

  useEffect(() => {
    const pageViewData = {
      page_title: title,
      page_path: location.pathname + location.search,
      page_location: window.location.href,
    };

    window.gtag('event', 'page_view', pageViewData);
  }, [title, location]);

  return <>{children}</>;
};
