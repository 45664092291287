import { createSlice, Dispatch, PayloadAction } from '@reduxjs/toolkit';
import {
  ErrorResponseType,
  ProductCategoryModelType,
} from '../../types';
import { productCategoriesIndexService } from '../../services';

// Define a type for the slice state
interface ProductCategoryState {
  isIndexFetching: boolean;
  indexErrorMessage?: string;
  productCategories: ProductCategoryModelType[];
  selectedProductCategory?: ProductCategoryModelType;
}

// Define the initial state using that type
const initialState: ProductCategoryState = {
  isIndexFetching: false,
  productCategories: [],
};

export const productCategorySlice = createSlice({
  name: 'productCategory',
  initialState,
  reducers: {
    setIndexFetching: (state: ProductCategoryState) => {
      state.isIndexFetching = true;
      state.indexErrorMessage = undefined;
    },
    setIndexFetchingSuccess: (
      state: ProductCategoryState,
      action: PayloadAction<ProductCategoryModelType[]>
    ) => {
      state.isIndexFetching = false;
      state.productCategories = action.payload;
    },
    setIndexFetchingError: (state: ProductCategoryState, action: PayloadAction<ErrorResponseType>) => {
      state.isIndexFetching = false;
      state.indexErrorMessage = action.payload.message;
    },
    setSelectedProductCategory: (state: ProductCategoryState, action: PayloadAction<ProductCategoryModelType>) => {
      state.selectedProductCategory = action.payload;
    },
  },
});

const { setIndexFetching, setIndexFetchingSuccess, setIndexFetchingError } = productCategorySlice.actions;
export const { setSelectedProductCategory } = productCategorySlice.actions;

export const productCategoryIndexAction = () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setIndexFetching());
    try {
      const response = await productCategoriesIndexService();
      dispatch(setIndexFetchingSuccess(response.data));
    } catch (err) {
      dispatch(setIndexFetchingError(err as ErrorResponseType));
    }
  };
