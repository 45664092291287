import React from 'react';
import './Footer.component.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

export const Footer = () => {

  return (
    <div className="Footer">
      <p>
        <a target="_blank" rel="noreferrer" href="https://instagram.com/refugio__bar">
          <FontAwesomeIcon icon={faInstagram} /> refugio__bar
        </a>
      </p>
      <p>Alem 216, Marcos Juárez, Córdoba</p>
    </div>
  );
};
