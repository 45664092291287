import React from 'react';
import './ProductCategoriesList.component.scss';
import { Product } from "../product";
import { ProductCategoryModelType } from "../../types";

type ProductListPropsType = {
  productCategories: ProductCategoryModelType[],
}

export const ProductCategoryList = ({productCategories}: ProductListPropsType) => {
  return (
    <div className="ProductCategoryList">
      {productCategories
        .filter((pc) => pc.products.length > 0
          || (
            pc.subcategories
            && pc.subcategories?.reduce(
              (total, s) =>
                total + s.products.length, 0
            ) > 0
          )
        ).map((pc) => (
          <React.Fragment key={`product-category-${pc.id}`}>
            {pc.products.length > 0 && (
              <section className="section" key={`section-${pc.id}`}>
                <h3 className="section-title" id={`product-category-${pc.id}`}>{pc.name}</h3>
                <div className="ProductGrid">
                  {(pc.products.map((p => (
                    <Product key={`product-${p.id}`} product={p}/>
                  ))))}
                </div>
              </section>
            )}
            {pc.subcategories?.filter((pc) => pc.products.length > 0).map((s) => (
              <section className="section" key={`section-subcategory-${s.id}`}>
                <h3 className="section-title" id={`product-category-${s.id}`}>{s.name}</h3>
                <div className="ProductGrid">
                  {(s.products.map((p => (
                    <Product key={`product-category-${p.id}`} product={p}/>
                  ))))}
                </div>
              </section>
            ))}
          </React.Fragment>
        ))}
    </div>
  );
};
