import React from 'react';
import './GoTop.component.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronUp } from "@fortawesome/free-solid-svg-icons";

export const GoTop = () => {
  const onClickHandler = () => {
    window.gtag('event', 'go_top_click', {
      event_category: 'interaction',
      event_label: 'go_top'
    });
    window.scrollTo({top: 0, behavior: "smooth"})
  }
  return (
    <div className="GoTop" onClick={onClickHandler}>
      <FontAwesomeIcon icon={faCircleChevronUp}/>
    </div>
  );
};
