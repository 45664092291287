import React, { ChangeEvent, useState, KeyboardEvent, useRef, useEffect } from 'react';
import './SearchBar.component.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";

type SearchBarPropsType = {
  onSearch: (q: string) => void,
  onClear: () => void,
};

export const SearchBar = ({onSearch, onClear}: SearchBarPropsType) => {
  const [criteria, setCriteria] = useState("");
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      window.gtag('event', 'search_interaction', {
        event_category: 'search',
        event_action: 'type',
        event_label: 'search_input',
        value: criteria,
      });
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [criteria])

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
    setCriteria(e.target.value);
  }

  const onClearHandler = () => {
    onClear();
    ref.current?.focus();
    window.gtag('event', 'search_interaction', {
      event_category: 'search',
      event_action: 'clear',
      event_label: 'clear_button',
      value: criteria,
    });
    setCriteria("");
  }

  const onKeyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
    if(e.key.toLowerCase() === "enter" || e.code.toLowerCase() === "enter"){
      ref.current?.blur();
      window.gtag('event', 'search_interaction', {
        event_category: 'search',
        event_action: 'submit',
        event_label: 'submit_button',
        value: criteria,
      });
    }
  }

  return (
    <div className="SearchBar">
      <FontAwesomeIcon icon={faSearch} className="SearchBar-icon"/>
      <input ref={ref} onKeyUp={onKeyPressHandler} value={criteria} type="text" onChange={onChangeHandler}/>
      <button className="SearchBar-clear" onClick={onClearHandler}>
        <FontAwesomeIcon icon={faTimes}/>
      </button>
    </div>
  );
};
