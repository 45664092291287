import React from "react";
import "./Header.component.scss";
import logo from "../../img/logo-full.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationPin } from "@fortawesome/free-solid-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

export const Header = () => {
  const trackLinkClick = (eventLabel: string) => {
    window.gtag("event", "link_click", {
      category: "interaction",
      action: "link_click",
      label: eventLabel,
    });
  };

  return (
    <div className="Header">
      <img src={logo} alt="" />
      <div className="Header-info">
        <p>
          <a
            href="https://goo.gl/maps/Re56QcgUEMGjGu9A7"
            rel="noreferrer"
            target="_blank"
            onClick={() => trackLinkClick("location")}
          >
            <FontAwesomeIcon icon={faLocationPin} />
            Alem 216, Marcos Juárez, Córdoba
          </a>
        </p>
        <p>
          <a
            href="https://instagram.com/refugio__bar"
            rel="noreferrer"
            target="_blank"
            onClick={() => trackLinkClick("instagram")}
          >
            <FontAwesomeIcon icon={faInstagram} />
            @refugio__bar
          </a>
        </p>
        <p>
          <a
            href="mailto:hola@refugio.bar"
            rel="noreferrer"
            target="_blank"
            onClick={() => trackLinkClick("envelope")}
          >
            <FontAwesomeIcon icon={faEnvelope} />
            hola@refugio.bar
          </a>
        </p>
      </div>
    </div>
  );
};
