import React from 'react';
import './Menu.component.scss';
import { ProductCategoryModelType } from "../../types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBeer,
  faBlender,
  faBottleWater,
  faBowlFood,
  faBreadSlice,
  faBurger,
  faCheese,
  faCoffee,
  faCookie,
  faGlassMartiniAlt,
  faHeart,
  faMugHot,
  faPizzaSlice,
  faUtensils,
  faWineBottle
} from '@fortawesome/free-solid-svg-icons'
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { useAppSelector } from "../../store";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar'

type MenuPropsType = {
  productCategories: ProductCategoryModelType[]
  onSelectProductCategory: (pc: ProductCategoryModelType) => void
}

const ICONS: Record<string, IconDefinition> = {
  '9': faCoffee,
  '1': faMugHot,
  '2': faBreadSlice,
  '3': faBurger,
  '15': faCheese,
  '13': faBlender,
  '4': faUtensils,
  '16': faCookie,
  '6': faBowlFood,
  '5': faPizzaSlice,
  '7': faBottleWater,
  '10': faHeart,
  '11': faBeer,
  '12': faGlassMartiniAlt,
  '14': faWineBottle,
};

export const Menu = ({productCategories, onSelectProductCategory}: MenuPropsType) => {
  const {selectedProductCategory} = useAppSelector(store => store.productCategory);

  const onClickMenuItemHandler = (pc: ProductCategoryModelType) => {
    window.gtag('event', 'menu_item_click', {
      event_category: 'navigation',
      event_label: pc.name,
    });
    onSelectProductCategory(pc)
  }

  return (
    <nav className="Menu">
    <PerfectScrollbar>
      <ul>
        {productCategories.map((pc) => (
          <React.Fragment key={`product-category-item-${pc.id}`}>
            <li className="Menu-item" onClick={() => onClickMenuItemHandler(pc)} key={`menu-item-${pc.id}`}>
              <div className={classNames("Menu-item-icon", {
                "Menu-item-icon--active": pc.id === selectedProductCategory?.id
              })}>
                <FontAwesomeIcon icon={ICONS[pc.id]}/>
              </div>
              <span>{pc.name}</span>
            </li>
            {pc.subcategories?.map((s) => (
              <li className="Menu-item" onClick={() => onClickMenuItemHandler(s)} key={`menu-item-${s.id}`}>
                <div className={classNames("Menu-item-icon", {
                  "Menu-item-icon--active": s.id === selectedProductCategory?.id
                })}>
                  <FontAwesomeIcon icon={ICONS[s.id]}/>
                </div>
                <span>{s.name}</span>
              </li>
            ))}
          </React.Fragment>
        ))}
      </ul>
        </PerfectScrollbar>
    </nav>
  );
};
