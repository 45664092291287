import React from 'react';
import './SinTaccIcon.component.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWheatAwn } from "@fortawesome/free-solid-svg-icons";

export const SinTaccIcon = () => {
  return (
    <div className="SinTaccIcon">
        <FontAwesomeIcon className="SinTaccIcon-wheat" icon={faWheatAwn} />
        <div className="SinTaccIcon-ban" />
    </div>
  );
};
